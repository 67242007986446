<template>
  <div class="container">
    <v-col cols="12" sm="6" class="mx-auto">

      <h3 class="mb-5"><b>Proceso de Registro</b></h3>
      <!--  -->
      <v-stepper v-model="pasoActual" vertical>
        <v-stepper-step :complete="pasoActual > 1" step="1" :editable="pasoActual > 1" color="rgb(94, 189, 153)">
          Datos personales
        </v-stepper-step>

        <v-stepper-content step="1">
          <!-- DATOS DE USUARIO -->
          <dataUser></dataUser>
          <!--  -->
        </v-stepper-content>
        <!-- ///////////// -->
        <v-stepper-step :complete="pasoActual > 2" step="2" :editable="pasoActual > 2" color="rgb(94, 189, 153)">
          Selecciona tu inscripción
        </v-stepper-step>

        <v-stepper-content step="2">
          <!-- INSCRIPCION -->
          <inscripcion></inscripcion>
          <!--  -->
        </v-stepper-content>
        <!-- ///////////// -->
        <v-stepper-step :complete="pasoActual > 3" step="3" :editable="pasoActual > 3" color="rgb(94, 189, 153)">
          Confirmar Registro
        </v-stepper-step>

        <v-stepper-content step="3">
          <!-- CONFIRMAR REGISTRO -->
          <confirmar></confirmar>
          <!--  -->
        </v-stepper-content>
        <!-- ///////////// -->
        <v-stepper-step step="4" color="rgb(94, 189, 153)">
          Procesar Pago
        </v-stepper-step>

        <v-stepper-content step="4">
          <!-- CONFIRMA REGISTRO Y PAGO -->
          <registro></registro> 
          <!--  -->
        </v-stepper-content>

      </v-stepper>
    </v-col>
    <Tcig :curso.sync="course.id" v-if="course"></Tcig>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import dataUser from './RegisterCourse/dataUser.vue'
import inscripcion from './RegisterCourse/inscripcion.vue'
import confirmar from './RegisterCourse/confirmar.vue'
import registro from './RegisterCourse/registro.vue'
import Tcig from '../../components/TciGComponent.vue'
export default {
  name: '',
  props:{
    // item:{
    //   type: Object
    // }
  },
  components: {
    dataUser,
    inscripcion,
    confirmar,
    registro,
    Tcig
  },
  data(){
    return{
      
    }
  },
  // watch:{
  // },
  filters: {

  },
  computed:{
    ...mapState('register',['paso','course']),
    ...mapState('auth',['user']),
    pasoActual: {
      get () {
        return this.paso
      },
      set (newValue) {
        return this.CAMBIO_PASO(newValue)
      }
    }
  },
  mounted(){
    this.CAMBIO_PASO(1)
  },
  created(){
    window.scrollTo(0,0)
    this.initRegistro()
  },
  methods:{
    ...mapMutations('register',['SET_USER','CAMBIO_PASO']),
    async initRegistro(){
      try{
        var obj = {
          slug: this.$route.query.course,
          areas_id: this.$route.query.area_id,
          users_id: this.user.id
        }
        const response = await this.$store.dispatch("register/REGISTERCOURSE", obj);
        if (response.data.curso == null) {
          this.$router.push('/')
        }
        if (response.data.estudiante == null) {
          this.SET_USER({
            name:     this.user.name,
            lastname: '',
            email:    this.user.email,
            phone:    '',
            dni:      '',
          })
        }else{
          this.SET_USER({
            name:     response.data.estudiante.name,
            lastname: response.data.estudiante.lastname,
            email:    response.data.estudiante.email,
            phone:    response.data.estudiante.phone,
            dni:      response.data.estudiante.dni,
            domicilio:response.data.estudiante.domicilio,
            localidad:response.data.estudiante.localidad,
            provincia:response.data.estudiante.provincia,

          })
        }
      }catch(e){
        if(e.response != undefined){
          // console.log(this.$router.history.current.query.course)
          if (e.response.status == 401) {
            this.$router.push('/register?course='+this.$router.history.current.query.course+'&area_id='+this.$router.history.current.query.area_id)
          }
        }
      }
    },
  }
}
</script>

<style lang="scss">

</style>
