<template>
  <v-container class="mx-2" >
    <v-row>
        <v-expansion-panels v-model="panel" >
          <v-expansion-panel v-for="(i, key) in foro_unidad" :key="key"
          @click="SET_FORO(i.id)"
          >
            <v-expansion-panel-header>{{ i.name }}</v-expansion-panel-header>
            <v-expansion-panel-content >

              <div class="cont-foro">
                <div v-html="i.descripcion"></div>
                <div class="cont-mensajes">
                  <v-col cols="12"  v-for="(m, key) in msm" :key="key">

                    <v-col cols="10" class="item-chat" 
                    :class="m.user.profesores_id != null ? 'profesor' : ''" 
                    :offset-md="m.user.profesores_id != null ? 2 : 0">
                      <v-list-item>
                        <v-list-item-content>
                          <div class="d-flex justify-space-between align-center">
                            <div>
                              <p class="mb-0"><b>{{ m.user.name }}</b></p>
                              <small v-if="m.user.profesores_id != null">Profesor</small>
                            </div>
                            <small>{{ m.created_at | date }}</small>
                          </div>
                          
                          <p style="line-height : 25px;">
                            {{ m.mensaje }}
                          </p>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>

                  </v-col>
                    <v-col cols="12" class="texta" v-if="unidad.id != null">
                      <v-textarea label="Escribe tu comentario" rows="2" v-model="mensaje" @keyup.enter="sendMensaje(i.id)" ></v-textarea>
                    </v-col>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      <h3 v-if="unidad.id == null"> Debe seleccionar una unidad para ver los mensajes</h3>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'

import moment from "moment";

export default {
  name: 'foro',
  props:{
  },
  components: {

  },
  data(){
    return{
      mensaje: '',
      panel: 0,
      msm: []

    }
  },
  filters: {
    date(value) {
      return moment(value).locale("es").format("d/m/Y HH:mm");
    },
  },
  computed:{
    ...mapState('auth',['user']),
    ...mapState('users',['curso_select', 'unidad','foro_unidad']),
    ...mapGetters('users',['mensajesFilter']),
    foro_unidad(){
      return this.unidad.foro_unidad
    }
  },
  mounted(){
    this.foro_unidad.forEach((element, index)=>{
      if (index == this.panel) {
        this.SET_FORO(element.id)
      }
    })
    this.msm = this.mensajesFilter
    
  },
  methods:{
    ...mapMutations('users',['SET_MENSAJES', 'SET_FORO']),
   async sendMensaje(i){
      var obj = {
        cursos_id         : this.curso_select.id,
        cursos_unidades_id: this.unidad.id,
        users_id          : this.user.id,
        mensaje           : this.mensaje,
        foro_actividades_id: i,
      }
      try{
         this.mensaje = ''
        const response = await this.$store.dispatch('users/SEND_MENSAJE',obj)
        if (response.data.status) {
          this.msm = response.data.mensajes
          this.SET_MENSAJES(response.data.mensajes)
        }
      }catch(e){
        console.log(e)
      }
     
    }
  
  }
}
</script>

<style lang="scss">

.cont-mensajes{ 
  position: relative;
  padding: 0px;
  border-radius: 8px;
  overflow: hidden;
  max-height: 500px;
  overflow-y: scroll;
  background-image: url('~@/assets/img/bgchat.jpg');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
   &::-webkit-scrollbar {
    background: rgba(0,0,0,.1);
    width: 7px;
   }
   &::-webkit-scrollbar-thumb{
    background: rgba(0,0,0,.2);
    &:hover{
      background: rgba(0,0,0,.3)

    }
   }
  .item-chat{
    background: #E0F2F1;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    .profesor{
      background: #E8F5E9;
    }
  }
  .texta{
    position: sticky;
    bottom: 0px;
    background: white;
    padding-bottom: 0px;
    box-shadow: 0px -5px 10px rgba(0,0,0,.08);
  }
  textarea{
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.profesor{
  background: rgba(#FFC107,.5) !important;
}
</style>
