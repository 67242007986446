var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"formulario_contacto"}},[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","md":"10"}},[_c('div',{staticClass:"row"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("Contáctanos")]),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"curso","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.areas,"item-text":"description","item-value":"id","filled":"","label":"Modalidad","error-messages":errors},model:{value:(_vm.area),callback:function ($$v) {_vm.area=$$v},expression:"area"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"curso","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.cursosFiltrados,"item-text":"name","item-value":"id","filled":"","label":"Curso","error-messages":errors},model:{value:(_vm.curso),callback:function ($$v) {_vm.curso=$$v},expression:"curso"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"name","label":"Nombre y Apellido","filled":"","error-messages":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"correo","rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"correo","label":"Correo","type":"email","filled":"","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"mensaje","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"name":"mensaje","label":"Mensaje","filled":"","error-messages":errors},model:{value:(_vm.mensaje),callback:function ($$v) {_vm.mensaje=$$v},expression:"mensaje"}})]}}],null,true)})],1)],1),_c('p',{staticClass:"contact-text font-weight-bold text-center grey--text text--darken-1"},[_c('v-spacer',{staticClass:"mb-12"}),_c('v-btn',{staticClass:"elevation-0 text-center px-8",attrs:{"color":"#5ebd99","rounded":"","dark":"","loading":_vm.loading},on:{"click":function($event){return passes(_vm.sendForm)}}},[_vm._v("Enviar")])],1)]}}])})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }