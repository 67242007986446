<template>
  <div>
    <v-card elevation="0" class="mb-12" v-if="curso_select">
      <div class="d-flex  flex-column ">
        <small>Total a pagar</small>
        <h1><b>{{ monto | monto }}</b></h1>
        <p>Selecciona el método de pago</p>

        <v-row class="boxRadio mb-4"
          :class="metodo == m.metodo ? 'active':''"
          v-for="(m,key) in metodosPagos"
          v-show="metodosPago.includes(m.metodo)"
          :key="key"
        >  
        <v-col cols="12" sm="1" md="1" class="d-flex align-center">
          <label :for="m.metodo" class=" mr-3 pago" 
            :class="metodo == m.metodo ? 'active':'' " 
            @click="metodo = m.metodo"
            >
            <input type="radio" name="btnpago" v-model="metodo" :id="m.metodo" :value="m.metodo"  class="form-control">
          </label>
        </v-col>
          <v-col cols="12" sm="5" md="5" v-if="m.name" class="d-flex align-center pointer" @click="metodo = m.metodo">
            {{ m.name }} 
          </v-col>
         <v-col v-if="m.img != null" cols="12" sm="3" md="3"  class="d-flex align-center">
            <img  :src="m.img" @click="metodo = m.metodo" class="w-100 logo" :alt="m.metodo">
          </v-col>
          <v-col v-if="m.component != null" cols="12" sm="6" md="6" :offset-sm="m.img != null ? 2 : 0" class="d-flex justify-end" >
            <component 
              v-if="m.metodo == metodo" 
              :pago_case="pago_case" 
              :monto="monto" 
              :monto_dolares="monto_dolares" 
              v-bind:is="m.component"></component>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
// import Swal from "sweetalert2";
// import PayPal from 'vue-paypal-checkout'
import mercadoPago from './mercadoPagoComponent.vue'
import efectivoPago from './efectivoPagoComponent.vue'
import paypalPago from './paypalPagoComponent.vue'

export default {
  props:{
    monto:{
      type: Number,
      required: true,
    },
    monto_dolares:{
      type: Number,
    },
    pago_case:{
      type: String,
      required: true,
    }
  },
  data() {
    return {
      loading: false,
      metodo: null,
      metodosPagos:[
        { 
          metodo:'MercadoPago',
          name: null,
          img: require('../../public/img/mercadoPagoLogo.png'),
          component: 'mercadoPago'
        },
        { 
          metodo:'Efectivo',
          name: 'Pagar en efectivo',
          img: null,
          component: 'efectivoPago'

        },
        { 
          metodo:'Paypal',
          name: null,
          img: require('../../public/img/PayPal-Logo.png'),
          component: 'paypalPago'
        },
      ],
      nmc: undefined,
    };
  },
  components: {
    mercadoPago,
    efectivoPago,
    paypalPago
  },
  computed:{
    ...mapState('users',['curso_select']),
    ...mapState('auth',['USER']),
    ...mapGetters('users',['metodosPago']),
  },
  watch:{
    // metodo: 'nameComponent'
  },
  filters: {
    monto(amount){
      amount += ''; // por si pasan un numero en vez de un string
      var amount_parts = amount.split('.'),
          regexp = /(\d+)(\d{3})/;
      while (regexp.test(amount_parts[0]))
          amount_parts[0] = amount_parts[0].replace(regexp, '$1' + '.' + '$2');
      return '$ '+amount_parts.join('.');
    }
  },
  mounted() {
    
  },
  created() {
  },
  methods: {
    ...mapMutations('register',['SET_CUPON','SET_DATA']),
    
    // async inscripcionCurso(){
    //   try{
    //     this.loading = true
    //     const response = await this.$store.dispatch("register/INSCRIPCION");
    //     if (response.data.status) {
    //       this.SET_DATA()
    //       this.$router.push('/user/home')
    //       Swal.fire('Registro Procesado', 'Te redirigiremos a tus cursos', 'success').then(()=>{
    //         // this.$router.push('/user/home')
    //       })
    //     }else{
    //     this.loading = false

    //       Swal.fire('Espera...', response.data.mensaje, 'error')
    //     }
    //   }catch(e){
    //     console.log(e.response.data)
    //   }
    // },
   
    
  },
};
</script>
<style lang="scss">
.ctn{
    width: 24px;
    position: relative;
    height: 24px;
}
@mixin radio{
    content: '';
    display: block;
    width: 11px;
    height: 11px;
    background: #1976d2;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
  }
  label.pago{
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 2px solid rgba(0,0,0,.3);
    position: relative;
    cursor: pointer;
    display: block;
    flex: none;
    transition: all .2s linear;
    &:hover{
      border-color: #1976d2;
    }
    &.active{
      border-color: #1976d2;
      &::before{
        @include radio;
      }
    }
    input{
      visibility: hidden;
    }
  }
  .boxRadio{
      border: 2px solid white;
      margin: 0px;
    &.active{
      border: 2px solid rgb(94, 189, 153);
      border-radius: 10px;
    }
  }
  .logo, .pointer{
    cursor: pointer;
  }
</style>