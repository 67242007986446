<template>
  <div class="container">
    <v-col cols="12" sm="6" class="mx-auto">
      <h3 class="mb-5"><b>Proceso registro de unidades</b></h3>
      <v-stepper v-model="e6" vertical>
        <v-stepper-step :complete="e6 > 1" step="1" :editable="e6 > 1" color="rgb(94, 189, 153)">
          Tu inscripción
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card elevation="0" class="mb-12" >
              <p class="mb-0"><small>Curso:</small></p>
              <p class=""><b>{{ course }}</b></p>

              <p class="mb-0"><small>Inscripción:</small></p>
              <p><b>{{ tipoInscripciones.name }}</b></p>

              <div class="" v-if="isMes">
                <p class="mb-0"><small><b>Cuotas pagadas:</b></small></p>
                 <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                       <tr v-for="i in cuotasPagadas" :key="i">
                         <td>{{ `Cuota ${i} - $ ${tipo.montCuotas}` }}</td>
                       </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
              </div>
              <!--  -->
              <div class="ms-3 mt-5" v-if="isMes">
                <p>Seleccionas tus cuotas a pagar ahora</p>
                <v-checkbox
                  v-for="i in cuotasPorPagar"
                  :key="i"
                  v-model="cuotasInsctitas"
                  :disabled="validaCuota[i]"
                  class="mt-0"
                  :value="i"
                  :label="`Cuota ${i+cuotasPagadas} - $ ${tipo.montCuotas}`"
                ></v-checkbox>
              </div>
              <!--  -->
              <p class="mb-0 mt-5"><small><b>Unidades Inscritas</b></small></p>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                   <tr v-for="(i, key) in unidadesInscritas" :key="key">
                     <td>{{ i.name }}</td>
                   </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <!--  -->
              <div v-if="tipo" class="ms-3 mt-5">
                <p>Unidades a Inscribir <small>(Selecciona las unidades que deseas cursar)</small></p>
                <v-checkbox
                  v-for="(i, key) in unidadesAinscribir"
                  :key="key"
                  v-model="unidadesInsctitas"
                  @change="unidadesI"
                  class="mt-0"
                  :value="i"
                  :disabled="validaUnidad[key]"
                  :label="nameUnidad(i)"
                ></v-checkbox>
              </div>
          </v-card>
          <v-btn color="rgb(94, 189, 153)" :disabled="validarSelectInscripcion" @click="e6 = 2">Continuar</v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 2" step="2" :editable="e6 > 2" color="rgb(94, 189, 153)">
          Confirmar Registro
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card elevation="0" class="mb-12" >
            <p v-if="isMes">Cantidad de cuotas a pagar: <b>{{ cuotasInsctitas.length }} Cuota<span v-if="cuotasInsctitas.length > 1"><b>s</b></span> de {{ tipo.montCuotas | monto }} </b></p>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                     Nuevas unidades a inscribir
                    </th>
                    <th class="text-left" v-if="!isMes">
                      Detalle
                    </th>
                  </tr>
                </thead>
                <tbody>
                 <tr v-for="(i, key) in unidadesInsctitas" :key="key">
                   <td>{{ i.name }}</td>
                   <td v-if="!isMes">{{ i.monto | monto }}</td>
                 </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
          <v-btn color="rgb(94, 189, 153)"  @click="e6 = 3"> Continuar</v-btn>
        </v-stepper-content>

        <v-stepper-step step="3" color="rgb(94, 189, 153)">
          Procesar Pago
        </v-stepper-step>
        <v-stepper-content step="3">
          <v-card class="mb-12" elevation="0">
            <metodosPago
              :monto="totalApagar"
              :monto_dolares="totalApagarDolares"
              :pago_case="'Unidades'"
            ></metodosPago>
          </v-card>
        </v-stepper-content>
      </v-stepper>
    </v-col>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import metodosPago from '../../components/metodosPagoComponent.vue'
import Swal from "sweetalert2";
export default {
  name: '',
  props:{
    item:{
      type: Object
    }
  },
  components: {
    metodosPago
  },
  data(){
    return{
      e6: 1,
      loading: false,
      valid: true,
      course: '',
      courseRules:[
        v => !!v || 'El Curso es requerido'
      ],
      cursos_id: null,
      checkbox: true,
      cuotasPagadas: null,
      unidadesInsctitas: [],
      unidadesInscritas: [],
      unidadesAinscribir: [],
      pagar: false,
      precio_inscripcion: null,
      tengocodigo: false,
      codigo: null,
      statusCodigo: null,
      mensajeCodigo: null,
      cupon_id: null,
      curso: null,
      cuotasInsctitas: [],
      tipoinscripcion_id: null,
      tipoInscripciones: {},
      inscripcion_id: null

    }
  },
  watch:{
    validaUnidad: 'llenaUnidades'
  },
  filters: {
    monto(amount){
      amount += ''; // por si pasan un numero en vez de un string
      var amount_parts = amount.split('.'),
          regexp = /(\d+)(\d{3})/;
      while (regexp.test(amount_parts[0]))
          amount_parts[0] = amount_parts[0].replace(regexp, '$1' + '.' + '$2');
      return '$ '+amount_parts.join('.');
    }
  },
  computed:{
    ...mapState(['URL_IMG']),
    ...mapState('users',['estudiante','cuponDescuento']),
    ...mapState('auth',['user']),
    ...mapGetters('auth',['USER']),
    calculoPago(){
      var suma = 0
      if (!this.isMes) {
        this.unidadesInsctitas.filter((element)=>{
          suma += element.monto
        })
      }else{
        suma = this.cuotasInsctitas.length * this.tipo.montCuotas
      }
      return suma
    },
    calculoPagoDolares(){
      var suma = 0
      if(!this.isMes) {
        this.unidadesInsctitas.filter((element)=>{
          suma += element.monto_dolar
        })
      }else{
        suma = this.cuotasInsctitas.length * this.tipo.montCuotasDolar
      }
      return suma
    },
    totalApagar(){
      var suma = 0
      suma += this.calculoPago
      // if (this.pagar) {
        // suma += this.precio_inscripcion
      // }
      if (Object.entries(this.cuponDescuento).length != 0) {
        var numero = (suma*this.cuponDescuento.porcentaje)/100
        suma -= numero
      }
      return suma
    },
    totalApagarDolares(){
      var suma = 0
      suma += this.calculoPagoDolares
      // if (this.pagar) {
        // suma += this.precio_inscripcion
      // }
      if (Object.entries(this.cuponDescuento).length != 0) {
        var numero = (suma*this.cuponDescuento.porcentaje)/100
        suma -= numero
      }
      return suma
    },
    tipo(){
      var e = null
      e = this.tipoInscripciones
      return e
    },
    cuotasPorPagar(){
      return this.tipo.cantCuotas - this.cuotasPagadas
    },
    validaUnidad(){
      if (this.isMes) {
        var unidadesMarcar = this.tipo.cantUnidades
        var calculo = this.cuotasInsctitas.length*unidadesMarcar
        var arr = []
        for (var i = 0; i < this.unidadesAinscribir.length; i++) {
          arr.push(true)
        }
        for (var il = 0; il < calculo; il++) {
          arr[il] = false
        }
        return arr
      }
      return []
    },
    validarSelectInscripcion(){
      if (this.isMes) {
        if (this.cuotasInsctitas.length == 0 || this.unidadesInsctitas.length == 0 ) {
          return true
        }
      }else{
        if (this.unidadesInsctitas.length == 0 ) {
          return true
        }
      }
      return false
    },
    isMes(){
      if (this.tipo) {

        if (this.tipo.tipo == 'mes') {
          return true
        }
        return
      }
      return false
    },
    validaCuota(){
      var arr = [false]
      if (this.tipo){
        for (var i = 0; i < this.tipo.cantCuotas; i++) {
          if(i < this.cuotasInsctitas.length){
            arr.push(false)
          }else{
            arr.push(true)
          }
        }
        var n = this.cuotasInsctitas.length+1
        arr[n] = false
      }
      return arr
    },
  },
  mounted(){
    this.courseMount({
      slug: this.$route.query.course,
      user_id: this.USER.id
    });
  },
  created(){
    this.registerCurso()
  },
  methods:{
    ...mapMutations('users',['SET_CUPON','SET_UNIDADES_INSCRITAS']),
    async courseMount(obj){
      try{
        await this.$store.dispatch('users/COURSE_SELECT',obj)
      }catch(e){
        if (e.response.status == 401) {
          this.CLEAR_DATA()
          this.CLEAR_TOKEN()
          this.$router.push(`/login`)
        }
      }
    },
    async registerCurso(){
      try{
        var obj = {
          slug: this.$route.query.course,
          users_id: this.user.id
        }
        const response = await this.$store.dispatch("users/REGISTERUNIDADES", obj);
        this.course = response.data.curso.name
        this.cursos_id = response.data.curso.id
        this.tipoInscripciones = response.data.tipoInscripcion
        this.cuotasPagadas = response.data.inscripcion.cuotas
        this.unidadesInscritas = response.data.curso.unidades
        this.unidadesAinscribir = response.data.otrasUnidades 
        this.inscripcion_id = response.data.inscripcion.id

      }catch(e){
        if (e.response.status == 401) {
          this.$router.push('/')
        }
      }
    },
    async inscripcionUnidades(){
      try{
        const response = await this.$store.dispatch("users/INSCRIPCIONUNIDADES");
        if (response.data.status) {
          Swal.fire('Registro Procesado', 'Te redirigiremos a tus cursos', 'success').then(()=>{
            this.$router.push('/user/home')
          })
        }else{
          Swal.fire('Espera...', response.data.mensaje, 'error')
        }
      }catch(e){
        console.log(e.response.data)
      }
    },
    nameUnidad(element){
      if (this.isMes) {
         return element.name
       }else{
          return element.name +'- Arancel: $'+ element.monto
       }
    },
    llenaUnidades(){
      this.unidadesInsctitas = []
      this.validaUnidad.forEach((element, index)=>{
          if (!element) {
            if (this.unidadesAinscribir.length >this.unidadesInsctitas.length) {
              this.unidadesInsctitas.push(this.unidadesAinscribir[index])
            }
          }
        })
    },
    unidadesI(){
      this.SET_UNIDADES_INSCRITAS(this.unidadesInsctitas)
    },
    validate () {
      this.inscripcionUnidades()
    },
  }
}
</script>

<style lang="scss">

</style>
