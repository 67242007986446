<template>
 <div>
   <iframe :src="ruta" width="100%" height="500px"></iframe>
 </div>
</template>

<script>

print;
export default {
  components: {

  },
  data: () => ({
    api: process.env.VUE_APP_ROOTAPI
  }),
  created() {
    // console.log(this.ruta)
  },
  computed: {
    ruta(){
      return `${this.api}/certificado/${this.$route.params.dni}/${this.$route.params.tipo}/${this.$route.params.id}`
    }
  },
  methods: {
  async showCertificate(){
    try{
      // console.log(this.$route.params)
      var obj = {
        dni: this.$route.params.dni,
        tipo: this.$route.params.tipo,
        certificadoid: this.$route.params.id
      }

     const response =  await this.$store.dispatch("certificate/SHOW",obj);
     console.log(response)
    }catch(e){
      console.log(e)
    }
  }
  },
};
</script>

<style>
.border-bold {
  border: 0.5px solid #000;
}
</style>