<template>
  <v-card elevation="0" class="mb-12">
    <v-text-field v-model="userLocal.name" name="name" :rules="nameRules" label="Nombre" required></v-text-field>

    <v-text-field v-model="userLocal.lastname" name="lastname" :rules="lastnameRules" label="Apellido" required></v-text-field>

    <v-text-field v-model="userLocal.email" name="email" :rules="emailRules" label="Correo Electrónico" disabled required></v-text-field>

    <v-text-field v-model="userLocal.phone" name="phone" :rules="phoneRules" label="Teléfono" required></v-text-field>

    <v-text-field v-model="userLocal.dni" max="11" name="dni" :rules="dniRules" label="Documento de identidad" required
    ></v-text-field>

     <v-text-field v-model="userLocal.pais" name="pais" :rules="paisRules" label="País" required></v-text-field>

    <v-text-field v-model="userLocal.domicilio" name="domicilio" :rules="domicilioRules" label="Domicilio" required></v-text-field>

    <v-text-field v-model="userLocal.localidad" name="localidad" :rules="localidadRules" label="Localidad" required></v-text-field>

    <v-text-field v-model="userLocal.provincia" name="provincia" :rules="provinciaRules" label="Provincia" required></v-text-field>

    <v-btn color="rgb(94, 189, 153)" :disabled="isValidationDatos" @click="CAMBIO_PASO(2),SET_USER(userLocal)"> Continuar</v-btn>

  </v-card>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
export default {
  data() {
    return {
      userLocal:{
        name:     '',
        lastname: '',
        email:    '',
        phone:    '',
        dni:      '',
        pais: '',
        domicilio: '',
        localidad: '',
        provincia: '',
      },
      nameRules: [
        v => !!v || 'El nombre es requerido',
      ],
      emailRules: [
        v => !!v || 'El correo es requerido',
        v => /.+@.+\..+/.test(v) || 'El correo es invalido',
      ], 
      lastnameRules:[
        v => !!v || 'El Apellido es requerido'
      ],
      phoneRules:[
        v => !!v || 'El Teléfono es requerido'
      ],
      dniRules:[
        v => !!v || 'El Documento es requerido'
      ],
      domicilioRules: [
        v => !!v || 'El domicilio es requerido',
      ],
      localidadRules: [
        v => !!v || 'La localidad es requerida',
      ],
      provinciaRules: [
        v => !!v || 'La provincia es requerida',
      ],
      paisRules: [
        v => !!v || 'El país es requerido',
      ],
      
    };
  },
  computed:{
    ...mapState('register',['user']),
    isValidationDatos(){
      if (this.userLocal.name == '' || this.userLocal.email == '' || this.userLocal.lastname == '' || this.userLocal.phone == '' || this.userLocal.dni == '' || this.userLocal.domicilio == '' || this.userLocal.localidad == '' || this.userLocal.provincia == ''){
        return true
      }
      return false
    },
  },
  watch:{
    user: 'cambiaUser'
  },
  mounted() {
  },
  created() {
  },
  methods: {
    ...mapMutations('register',['SET_USER','CAMBIO_PASO']),
    cambiaUser(){
      this.userLocal = this.user
    }
    
  },
};
</script>
<style>
</style>