<template>
 <div>
  <label for="cuponDescuento">Poseo un cupon de descuento</label>
  <input type="checkbox" id="cuponDescuento" v-model="tengocodigo">

  <v-text-field 
    :error="statusCodigo == true" 
    :messages="mensajeCodigo" 
    :success="statusCodigo == false" 
    v-if="tengocodigo" 
    v-model="codigo" 
    style="width: 100%"
    name="codigo" label="Ingrese su cupón de descuento" 
    @keyup="buscaCodigo"
  ></v-text-field>

 </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'

import moment from "moment";

export default {
  name: 'CuponDescuento',
  props:{
  },
  components: {

  },
  data(){
    return{
      tengocodigo: false,
      codigo: null,
      statusCodigo: null,
      mensajeCodigo: null,
    }
  },
  filters: {
    date(value) {
      return moment(value).locale("es").format("d/m/Y");
    },
  },
  computed:{
     ...mapState('register',['course','user', 'cupon']),
    ...mapGetters('register',['totalApagar'])
  
   
  },
  mounted(){
    // console.log(this.evaluacion.resuelto)
  },
  methods:{
    ...mapMutations('register',['SET_CUPON']),
    async buscaCodigo(){
      var obj = {
        email:  this.user.email,
        codigo: this.codigo,
        cursos_id: this.course.id
      }
      const response = await this.$store.dispatch("register/CONSULTA_CUPON", obj);
      if (response.data.status) {
        this.statusCodigo = false
        this.mensajeCodigo = response.data.mensaje
        this.SET_CUPON(response.data)
      }else{
        // Swal.fire('Espera...', response.data.mensaje, 'error')
       this.statusCodigo = true
       this.mensajeCodigo = response.data.mensaje
       this.SET_CUPON(null)
      } 
    },
  
  }
}
</script>

<style  lang="scss">
#cuponDescuento{
  margin-left: 10px;
}
</style>
