<template>
  <div>
    <div id="paypal-button-container"></div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { loadScript } from "@paypal/paypal-js";
export default {
  name: 'paypalPago',
  props:{
    monto_dolares:{
      type: Number,
    },
    pago_case:{
      type: String
    }
  },
  components: {

  },
  data(){
    return{

    }
  },
  computed:{
    ...mapState('register',['course','cursoTotal','users_id','inscripcionData']),
    ...mapState('auth',['authUser','user']),
    ...mapState('home',['config']),
    ...mapGetters('register',['totalApagarDolares']),
    ...mapState('users',['curso_select','unidades_inscritas']),
    inscripcion_id(){
      if (this.inscripcionData.id) {
        return this.inscripcionData.id
      }else{
        return this.curso_select.inscripcion
      }
    },
    montoDolares(){
      if(this.totalApagarDolares){
        return this.totalApagarDolares
      }else{
        return this.monto_dolares
      }
    },
    curso(){
      if(this.course){
        return this.course
      }else{
        return this.curso_select
      }
    }
  },
  create(){
  },
  mounted(){
    loadScript({ "client-id": process.env.VUE_APP_PAYPAL_ID }).then((paypal) => {
        this.initPayPalButton(paypal)
    }).catch((err) => {
        console.error("failed to load the PayPal JS SDK script", err);
    });
  },
  methods:{
    initPayPalButton(paypal){
      if (this.pago_case == 'Unidades') {
        this.solicitudUnidades()
      }
      paypal.Buttons({
        createOrder: (data, actions)=> {
          // This function sets up the details of the transaction, including the amount and line item details.
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: this.montoDolares
              }
            }]
          });
        },
        onApprove: (data, actions)=> {
          // This function captures the funds from the transaction.
          return actions.order.capture().then((details)=> {

            if (details.status == "COMPLETED") {
              this.$router.push(`/success/${this.curso.id}/${this.user.id}/${this.inscripcion_id}?metodopago=Paypal&payment_id=${details.id}&pago_case=${this.pago_case}`)
            }
          });
        }
      }).render('#paypal-button-container');
    },
    async solicitudUnidades(){
      try{
        let obj = {
          inscripcion_id: this.inscripcion_id,
          unidades: this.unidades_inscritas,
          metodo_pago: 'Automatico',
        }
        const response = await this.$store.dispatch("register/SOLICITUDES_UNIDADES", obj);
        return response
      }catch (e){
        console.log(e)
      }
    }
  }
}
</script>


