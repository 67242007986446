<template>
    <div>
        <span v-if="seconds &&  countdown != '00:00:00'">{{ countdown }}</span>
        <span v-if="mensaje && countdown == '00:00:00'" style="font-size: .8rem">{{ mensaje }}</span>
    </div>
</template>

<script>
    import * as moment from 'moment';
    export default {
        name: 'CountDown',
        props: {
            seconds: {
                type: Number,
                required: true,
            },
            mensaje:{
                type: String,
                default: null
            }
        },
        data () {
            return {
                countdown: null,
                expires_in: null,
                interval: null,
            }
        },
        mounted () {
            this.countdown = moment.utc(this.seconds).format('HH:mm:ss');
            this.expires_in = this.seconds;
            this._setInterval();
            this.montado = true
        },
        destroyed () {
            clearInterval(this.interval);
        },
        methods: {
            _setInterval: function() {
                this.interval = setInterval(() => {
                    if (this.expires_in === 1) {
                        clearInterval(this.interval);
                    } else {
                        this.expires_in -= 1;
                        this.countdown = moment.utc(this.expires_in * 1000).subtract(1, 'seconds').format('HH:mm:ss');
                    }
                }, 1000);
            },
        }
    }
</script>