<template>
  <section id="formulario_contacto">
    <v-col cols="12" md="10" class="mx-auto">
    <div class="row">
      <v-col cols="12">
        <h1>Contáctanos</h1>
        <ValidationObserver ref="obs" v-slot="{ passes }">
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <ValidationProvider name="curso" rules="required" v-slot="{ errors }">
                <v-select
                  :items="areas"
                  item-text="description"
                  item-value="id"
                  v-model="area"
                  filled
                  label="Modalidad"
                  :error-messages="errors"
                ></v-select>

              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <ValidationProvider name="curso" rules="required" v-slot="{ errors }">
                <v-select
                  :items="cursosFiltrados"
                  item-text="name"
                  item-value="id"
                  v-model="curso"
                  filled
                  label="Curso"
                  :error-messages="errors"
                ></v-select>
              </ValidationProvider>
            </v-col>
            
            <v-col cols="12" sm="6" md="6">
              <ValidationProvider name="nombre" rules="required" v-slot="{ errors }">
                <v-text-field
                  v-model="name"
                  name="name"
                  label="Nombre y Apellido"
                  filled
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
       
            <v-col cols="12" sm="6" md="6">
              <ValidationProvider name="correo" rules="email|required" v-slot="{ errors }">
                <v-text-field
                  v-model="email"
                  name="correo"
                  label="Correo"
                  type="email"
                  filled
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            
            <v-col cols="12">
              <ValidationProvider name="mensaje" rules="" v-slot="{ errors }">
                <v-textarea
                  v-model="mensaje"
                  name="mensaje"
                  label="Mensaje"
                  filled
                  :error-messages="errors"
                ></v-textarea>

              </ValidationProvider>
            </v-col>

          </v-row>
          <p class="contact-text font-weight-bold text-center grey--text text--darken-1">
            <v-spacer class="mb-12"></v-spacer>
            <v-btn
              color="#5ebd99"
              rounded
              class="elevation-0 text-center px-8"
              dark
              :loading="loading"
              @click="passes(sendForm)"
              >Enviar</v-btn>
          </p>
        </ValidationObserver>
      </v-col>
    </div>
    </v-col>
  </section>
</template>
<script>
import Swal from "sweetalert2";
import { mapState } from 'vuex'
export default {
  components:{

  },
  data() {
    return {     
      curso: null,
      name: null,
      email: null,
      loading: false,
      mensaje: null,
      area: null
    };
  },
  metaInfo () {
    return {
      meta: [
        { name: 'description', content: 'Inscripción de curso en IDIP' }
      ]
    }
  },
  computed:{
    ...mapState('home',['cursos_activos','areas']),
    cursosFiltrados(){
      if (this.cursos_activos && this.area) {
        return this.cursos_activos.filter((element)=>element.area.id == this.area)
      }
      return []
    }
  },
  created(){
    window.scrollTo(0,0)
  },
  methods: {
    nameSelect(i){
      return i.name + ' ('+ i.area.description +') '
    },
    async sendForm(){
      this.loading = true
      var obj = {
        curso:   this.curso, 
        name:    this.name, 
        email:   this.email, 
        mensaje: this.mensaje, 
      }
      try{
        const response = await this.$store.dispatch("contact/CONTACTO", obj);
        if (response.data.status) {
          this.loading = false
          this.$gtag.event('Contactar', {'event_category': 'Contactar','event_action': 'Envio', 'event_label': 'General', 'value': '100'})
          // gtag('event', 'Contactar', {'event_category': 'Contactar','event_action': 'Envio', 'event_label': 'General', 'value': '100'})
          Swal.fire('Mensaje enviado', response.data.mensaje, 'success').then(()=>{
            
            this.$router.push('/')
          })
        }else{
          Swal.fire('Opps...', response.data.mensaje, 'error')
        }
      }catch (e){
        console.log(e)
      }

    }
  },
};
</script>
<style>

</style>