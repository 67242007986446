<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="#5ebd99">
          <v-btn icon dark @click="cerrarEvaluacion">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ evaluacion.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-col cols="12" class="my-4" v-html="evaluacion.description"></v-col>
        <v-spacer></v-spacer>
        <v-divider></v-divider>
        <v-col cols="12" class="d-flex justify-space-between align-center w-100 p-3" v-if="finalizado">
          <div>
            <h3>Resultados</h3>
            <small>Ejercicio realizado el: {{ solucion.fecha }} </small><br>
            <p class="mb-0" v-if="solucion.contador.status">Próximo intento en:
              <CountDown :seconds="solucion.contador.segundos" :mensaje="'Recarga la página para intentarlo nuevamente'"></CountDown>
            </p>
          </div>
          <h2 class=" ">
          <strong 
            v-if="solucion.resultado != ''"
            :class="solucion.resultado == 'APROBADO' ? 'success--text':'red--text'">
            {{ solucion.resultado }}
          </strong>
           <!--  <strong v-else>
              En Revisión
            </strong> -->
          </h2>
          <div class="text-end">
            <h2 v-if="solucion.resultado != '' "><b>{{ solucion.puntuacion }}/{{ evaluacion.puntaje }}</b></h2>
            <small>Mínimo aprobatorio: {{ evaluacion.minimo }}</small>
          </div>
        </v-col>
        <v-divider></v-divider> 

        
        <div class="container" v-if="finalizado">
          <div v-for="(p, key) in evaluacion.preguntas" :key="key">
            <v-col cols="12" class="d-flex " :class="p.tipoo == 'SS' ? 'justify-start':'justify-space-between'" > 
              <div v-html="p.pregunta" class="w-100"></div>
              <div v-if="p.tipoo == 'PA' && p.resultado.puntaje">
                <h3><b>{{ p.resultado.puntaje }}pts.</b></h3>
              </div>
            </v-col>
            <v-col cols="12" class="" v-if="p.tipoo == 'SS'">
              <v-radio-group v-model="p.respuesta_id" >
                <v-radio
                  v-for="(r, keyy) in p.respuestas" :key="keyy"
                  :value="r.id" disabled >
                  <template v-slot:label>
                    <span class="me-4">{{ r.respuesta }} </span>
                    <span class="font-weight-bold success--text" v-if="r.resultado && p.respuesta_id == r.id" >Correcto</span>
                    <span class="font-weight-bold red--text" v-if="!r.resultado && p.respuesta_id == r.id" >Incorrecto</span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" v-else>
              <v-textarea
                solo
                v-model="p.resultado.respuesta"
                disabled
              ></v-textarea>
            </v-col>
              
          </div>
        </div>

        <div class="container" v-if="!finalizado">

          <v-stepper v-model="e1">
            <v-stepper-items>

              <v-stepper-content :step="key" v-for="(p, key) in evaluacion.preguntas" :key="key">
                <v-col cols="12" v-html="p.pregunta"> </v-col>
                
                <v-col cols="12" class="mb-5" v-if="p.tipoo == 'SS'">
                  <v-radio-group v-model="ress" >
                    <v-radio
                      v-for="(r, keyy) in p.respuestas"
                      :key="keyy"
                      :label="r.respuesta"
                      :value="r"
                      @change="responder(p.id, r)"
                    ></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" v-else-if="p.tipoo == 'PA'">
                  <v-textarea
                    solo
                    v-model="ress"
                    label="Respuesta"
                    @keyup="responder(p.id,ress)"
                  ></v-textarea>
                </v-col>

                <v-btn v-if="evaluacion.preguntas.length == (key+1)" 
                      :disabled="statusBoton"
                      @click="finActividad"
                      :loading="loading"
                      color="warning" >
                  Finalizar
                </v-btn>
                <v-btn v-else color="#5ebd99" :disabled="statusBoton" @click="siguientePregunta()">
                  Continuar
                </v-btn>
              </v-stepper-content>

            </v-stepper-items>
          </v-stepper>
        </div>

      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import Swal from "sweetalert2";
import moment from "moment";
import CountDown from '../components/CountDown.vue'

export default {
  name: 'modal-evaluaciones',
  props:{
  },
  components: {
    CountDown
  },
  data(){
    return{
      notifications: false,
      sound: true,
      widgets: false,
      e1: 0,
      ress: null,
      respuestas: [],
      statusBoton: true,
      finalizado: false,
      loading: false,
      solucion: {
        resultado: '',
        fecha: '',
        puntuacion: 0,
        contador: {
          status: false,
          segundos: 0
        }
      }
    }
  },
  filters: {
    date(value) {
      return moment(value).locale("es").format("d/m/Y");
    },
  },
  watch:{
    evaluacion: 'validaActividades'
  },
  computed:{
    ...mapState('users',['dialogEvaluacion','evaluacion']),
    ...mapGetters('auth',['USER']),
    dialog(){
      return this.dialogEvaluacion
    },
    puntuacion(){
      var suma = 0
      this.respuestas.filter((element)=>{
        if (element.respuesta.vf == 'V') {
          this.evaluacion.preguntas.filter((e)=>{
            if (e.id == element.pregunta) {
              suma+= e.puntaje
            }
          })
        }
      })
      return suma
    },
    resultado(){
      if ( this.puntuacion >= this.evaluacion.puntaje ) {
        return 'APROBADO'
      }
      return 'REPROBADO'
    },
  },
  mounted(){
    // this.e1 = 0
    // this.CHANGE_DIALOG_EVALUACION(false)  
  },
  methods:{
    ...mapMutations('users',['CHANGE_DIALOG_EVALUACION','SET_EVALUACION']),

    async finActividad(){
       this.statusBoton = true
       this.loading = true
      try{
        var obj = {
          id: this.evaluacion.id,
          evaluacion: this.evaluacion,
          datos: this.respuestas,
          users_id: this.USER.id,
          status: this.resultado,
          puntaje: this.puntuacion
        }

        await this.$store.dispatch('users/SEND_ACTIVIDAD',obj)
        if(this.evaluacion.tipoo == 'SS'){
          Swal.fire(
            'Genial!',
            'Tus respuestas han sido almacenadas.',
            'success'
          ).then(()=>{
            this.CHANGE_DIALOG_EVALUACION(false)
            this.statusBoton = false
            this.e1 = 0
            this.course({
              slug: this.$route.params.slug,
              user_id: this.USER.id
            }).then(()=>{
              this.finalizado = true
            })
            
          })
         
        }else{
          Swal.fire(
            'Genial!',
            'Tus respuestas han sido almacenadas.',
            'success'
          ).then(()=>{
            this.CHANGE_DIALOG_EVALUACION(false)
            this.statusBoton = false
            this.e1 = 0
            this.course({
              slug: this.$route.params.slug,
              user_id: this.USER.id
            }).then(()=>{
              // this.finalizado = true  
            })
            
          })
        }
      }catch(e){
        Swal.fire(
          'Espera!',
          e.response.data.mensaje,
          'error'
        ).then(()=>{
          this.CHANGE_DIALOG_EVALUACION(false)
          this.e1 = 0
          this.finalizado = false
        })
      }
      this.ress = null
      this.respuestas = []
      this.loading = false
    },
    async course(obj){
      try{
        await this.$store.dispatch('users/COURSE_SELECT',obj)
        this.validaActividades()
      }catch(e){
        console.log(e.response)
      }
    },
    siguientePregunta(){
      this.ress = null
      this.statusBoton = true
      this.e1++
    },
    responder(pregunta, respuesta){
      var index = this.respuestas.findIndex((element)=> element.pregunta == pregunta)
      var obj = {
        pregunta: pregunta,
        respuesta: respuesta
      }
      if (index == -1) {
        this.respuestas.push(obj)
      }else{
        this.respuestas.filter((element, i)=>{
          if (i == index) {
            element.respuesta = respuesta
          }
        })
      }
      this.validarRespuesta(pregunta)
    },
    validarRespuesta(pregunta){
      var index = this.respuestas.findIndex((element)=> element.pregunta == pregunta)
      if (index == -1) {
        this.statusBoton = true
      }else{
        this.statusBoton = false
      }
    },
    validaActividades(){
      this.finalizado = false

      if (Object.keys(this.evaluacion).length != 0) {

        if (this.evaluacion.ultimo_intento.status == 'SIN INTENTOS') {
          this.finalizado = false
        }

        if (this.evaluacion.ultimo_intento.status != 'SIN INTENTOS') {

          if (this.evaluacion.ultimo_intento.status == 'APROBADO' || (this.evaluacion.ultimo_intento.status == 'REPROBADO' && this.evaluacion.ultimo_intento.proximo_intento  > 0)) {
            this.finalizado = true
            this.solucion.fecha = this.evaluacion.ultimo_intento.fecha
            this.solucion.resultado = this.evaluacion.ultimo_intento.status
            this.solucion.puntuacion = this.evaluacion.ultimo_intento.calificacion
            if (this.evaluacion.proximo_intento > 0) {
              this.solucion.contador.status = true
              this.solucion.contador.segundos = this.evaluacion.proximo_intento
            }
          }

          if (this.evaluacion.ultimo_intento.status == 'REPROBADO' && this.evaluacion.ultimo_intento.proximo_intento == 0) {
            this.finalizado = false
          }
        
        }

      }

    },
    respuestaCorrecta(respuestas){
      let r = 0
      respuestas.filter((element)=> {
        if (element.resultado == true) {
          r = element.id
        }
      })
      return r
    },
    cerrarEvaluacion(){
      this.e1 = 0
      // this.SET_EVALUACION({})
      this.CHANGE_DIALOG_EVALUACION(false)
    }
  }
}
</script>

<style lang="scss">

</style>
