<template>
  <v-row justify="center" >
    <v-dialog v-model="dialog" fullscreen hide-overlay persistent  transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="#5ebd99">
          <v-btn icon dark @click="CHANGE_DIALOG_CLASE(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ clase.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <!-- <v-col v-if="clase.video != null" cols="12" class="my-4 justify-center d-flex">
          <video :src="URL_IMG+clase.video" width="50%" controls=""></video>
        </v-col> -->

        <v-col cols="12" class="my-4" v-html="clase.description"></v-col>
        
       <div class="arrowUp" @click="arrowTop">
         <i class="fas fa-arrow-up"></i>
       </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'

import moment from "moment";

export default {
  name: 'modal-evaluaciones',
  props:{
  },
  components: {

  },
  data(){
    return{
      notifications: false,
      sound: true,
      widgets: false,
      e1: 0,
      ress: null,
      respuestas: [],
      statusBoton: true,
      finalizado: false,
      solucion: {
        resultado: '',
        fecha: '',
      }
    }
  },
  filters: {
    date(value) {
      return moment(value).locale("es").format("d/m/Y");
    },
  },
  computed:{
    ...mapState(['URL_IMG']),
    ...mapState('users',['dialogClase','clase']),
    ...mapGetters('auth',['USER']),
    dialog(){
      return this.dialogClase
    },
   
  },
  mounted(){
    // console.log(this.evaluacion.resuelto)
  },
  methods:{
    ...mapMutations('users',['CHANGE_DIALOG_CLASE']),
    arrowTop(){
     let e = document.querySelector('.v-dialog.v-dialog--active.v-dialog--persistent.v-dialog--fullscreen')
     e.scrollTo(0, 0)
    }
  
  }
}
</script>

<style lang="scss">

.arrowUp{
      width: 40px;
    height: 40px;
    border-radius: 10px;
    background: white;
    box-shadow: -3px 4px 13px rgb(0 0 0 / 40%);
    position: fixed;
    z-index: 999999;
    right: 40px;
    bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover{
      background: #5ebd99;
    }
}
</style>
