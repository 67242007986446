<template>
<v-container fluid class="py-0 px-0">
  <section id="img_principal_beneficios">
      <v-row>
          <v-col cols="12" class="px-3 py-0">
              <v-img class="white--text align-center" :src="URL_IMG+banner_beneficios" >
              </v-img>
          </v-col>
      </v-row>
  </section>

  <section id="img_text" >         
      <span v-for="(item,i) in beneficios" :key="i">
          <v-row no-gutters :class="(i == 0) ? 'mt-3' : 'p'" fill-height>
              <v-col cols="12" sm="6" 
                  :class="(i%2) ? 'text-center d-flex stretch order-1' : 'text-center d-flex stretch order-0'" 
                  style="background-color: #5ebd99">
                  <v-card color="transparent" class="border-radius-0 d-flex align-center mx-auto" flat>
                       
                      <div class="py-4 px-4 white--text text-center">
                          <span class="display-1-text">DESCUENTO</span>
                          <v-spacer></v-spacer>
                          <span class="display-4-text semi-bold">{{item.porcentaje}} %</span>
                          <v-spacer class="my-5"></v-spacer>
                          <div v-html="item.description"></div>
                          <v-spacer></v-spacer>
                          <v-btn color="white" v-if="item.form == 1" rounded class="mt-5" @click="solicitar(item)">Solicitar descuento</v-btn>                   
                      </div>

                  </v-card>
              </v-col>
              <v-col cols="12" sm="6" 
                  :class="(i%2) ? 'd-flex stretch order-0' : 'd-flex stretch order-0'">
                  <v-img :src="URL_IMG+item.img" ></v-img>
              </v-col>
          </v-row>        
      </span>
  </section>

  <section id="becas">
      <v-row class="mt-10 bg-brocha">
          <v-col cols="12" sm="6" md="6">
              <v-card class="text-center px-5" flat color="transparent">
                  <!-- <v-icon style="font-size: 150px">mdi-school-outline</v-icon> -->
                  <v-avatar
                    size="200"
                    tile
                  >
                    <img src="/img/mortarboard.svg" alt="alt">
                  </v-avatar>
                  <v-spacer class="my-5"></v-spacer>
                 <div v-html="be_segunda"></div>
              </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="6">
              <!-- <v-card class="d-flex align-center" flat color="transparent"> -->
                  <v-img src="/img/becas.png" height="100%" width="400" class="mx-auto"></v-img>
              <!-- </v-card> -->
          </v-col>
      </v-row>
  </section>

  <section id="texto_grey">
      <v-row align="center" justify="center">
          <v-col cols="12">
              <v-card color="grey lighten-5" flat>
                  <v-row align="center" justify="center" class="text-center">
                      <v-col cols="8" v-html="be_tercera">
                            
                      </v-col>
                  </v-row>
              </v-card>
          </v-col>
      </v-row>
  </section>

  <section id="ademas">
      <v-row align="center" justify="center" fill-height>
          <v-col cols="12" class="text-center">
              <p class="font-weight-bold display-1-text grey--text text--lighten-1">
                  Y ADEMAS...
              </p>
          </v-col>
          <v-col cols="12" sm="10">
              <v-row>

                  <v-col cols="12" sm="6" v-for="(item,i) in be_lista" :key="i" class="d-flex align-stretch">
                      <v-card :class="(i !== 7) ? 'shadow-small' : 'd-flex stretch'" flat width="100%">
                          <v-card-text class="text-center">
                              <v-avatar
                                size="30"
                                tile
                              >
                                <img :src="URL_IMG+item.img" alt="alt">
                              </v-avatar>
                              <v-spacer class="my-5"></v-spacer>
                              <p class="text-uppercase black--text">
                                  {{item.title}}
                              </p>                                    
                          </v-card-text>                              
                      </v-card>
                  </v-col>

              </v-row>                    
          </v-col>
      </v-row>
  </section>

  <template>
    <v-col justify="center">
      <v-dialog v-model="dialog" persistent>
        <v-card>
          <v-card-title class="headline d-flex justify-space-between">
            <span>Envíanos un mensaje para solicitar tu descuento</span>
            <v-btn @click="dialog = false" icon color="red">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <formularioContacto
          :isCurso="false"
          :widthsm="4"
          :isFile="true"
          :type="'descuento'"
          :descuento="descuento"
          v-on:retorno="enviado"
          ></formularioContacto>
        </v-card>
      </v-dialog>
    </v-col>
  </template>
</v-container>
</template>
<script>
  import { mapState, mapGetters } from 'vuex'
  import formularioContacto from '../../components/formularioContacto.vue'
    export default {
        data () {
            return {
              dialog: false,
              descuento: '',
                
                ademas: [
                    {
                        img: '/img/calendar.svg',
                        text: 'PODES INICIAR TODOS LOS MESES DEL AÑO YA QUE LOS CURSOS SON DE INICIACIÓN CONTÍNUA.'
                    },
                    {
                        img: '/img/online-course.svg',
                        text: 'TE DAMOS LA POSIBILIDAD DE CURSAR UNA VEZ POR SEMANA O UN SÁBADO POR MES.'
                    },
                    {
                        img: '/img/homework.svg',
                        text: 'INCLUIMOS MATERIAL DE ESTUDIO COMO APOYO A LO DESARROLLADO EN CLASE, EN TODAS LAS ACTIVIDADES.'
                    },
                    {
                        img: '/img/files.svg',
                        text: 'ENTREGAMOS CONSTANCIA DE ESTUDIOS.'
                    },
                    {
                        img: '/img/group_black.svg',
                        text: 'DICTAMOS CURSOS PERSONALIZADOS INDIVIDUALES O GRUPALES.'
                    },
                    {
                        img: '/img/air-conditioner.svg',
                        text: 'AMBIENTE CLIMATIZADO ABIERTO TODO EL AÑO.'
                    },
                    {
                        img: '/img/hand.svg',
                        text: 'SE ACEPTAN PAGOS CON TARJETA DE DÉBITO, CRÉDITO, TRANSFERENCIA O DEPÓSITO BANCARIO Y POR MERCADOPAGO.'
                    },
                    // {
                    //     img: '',
                    //     text: 'INSCRIBITE HOY Y OBTENE LOS DESCUENTOS DE INMEDIATO'
                    // },

                ]
            }
        },
        components:{
          formularioContacto
        },
        created(){
            window.scrollTo(0,0)
        },
        computed:{
          ...mapState(['URL_IMG']),
          ...mapState('home',['beneficios']),
          ...mapGetters('home',['banner_beneficios','be_segunda','be_tercera','be_lista'])
        },
        methods: {
          solicitar(item){
            this.dialog = true
            this.descuento = item.text
          },
          enviado(){
            this.dialog = false
          }
        }
    }
</script>
<style>
    .border-radius-0 {
        border-radius: 0px !important
    }
    .shadow-small {
        box-shadow: 0 2px 4px rgba(27, 31, 35, 0.15) !important;
    }
</style>