<template>
  <v-card elevation="0" class="mb-12" >
    <v-text-field
      :value="courseName"
      name="course"
      :rules="courseRules"
      disabled
      label="Nombre del Curso"
      required
    ></v-text-field>
    <!--  -->
    <div class="" v-if="tipoInscripciones.length > 0">
      <p>¿Cómo lo quieres cursar?</p>
      <v-radio-group v-model="tipoinscripcion_id" >
        <v-radio
          v-for="(i, key) in tipoInscripciones"
          required
          :rules="tipoInscripcionesRules"
          :key="key"
          :label="i.name"
          :value="i.id"
          @change="SET_TIPOINSCRIPCION_ID(i.id), SET_UNIDADES([]), unidadesInscritas = []"
        ></v-radio>
      </v-radio-group>
    </div>
    <!--  -->
    <div class="" v-if="fechas.length > 0 && ifFechas">
      <p>Selecciona una fecha y horario</p>
      <v-radio-group v-model="fecha">
        <v-radio
          v-for="(i, key) in fechas"
          :key="key"
          :label="`${i.fecha} - ${i.horario}`"
          :value="i.id"
          @change="SET_FECHA_ID(i.id)"
        ></v-radio>
      </v-radio-group>
    </div>
    <!--  -->
    <div class="" v-if="isMes ">
      <p>Seleccionas tus cuotas a pagar ahora</p>
      <v-checkbox
      v-if="tipo.descuento != null"
      :label="`Precio promocional por pago total - $ ${montoTotalCuotas} `"
      v-model="cursoTotall"
      :value="true"
      @change="totalCurso"
      ></v-checkbox>

      <v-checkbox
        v-for="i in tipo.cantCuotas"
        v-show="!cursoTotall"
        :key="i"
        v-model="cuotasInscritas"
        class="mt-0"
        :value="i"
        :label="`Cuota ${i} - $ ${tipo.montCuotas}`"
        :disabled="validaCuota[i]"
        @change="SET_CUOTAS(cuotasInscritas)"
      ></v-checkbox>
    </div>
    <!--  -->

    <div v-if="(course && course.unidades.length > 0) && tipoinscripcion_id != null">
      <p>Unidades a Inscribir <small>(Selecciona las unidades que deseas cursar)</small></p>
      <v-checkbox
        v-for="(i, key) in course.unidades"
        :key="key"
        v-model="unidadesInscritas"
        class="mt-0"
        :value="i"
        :disabled="validaUnidad[key]"
        :label="nameUnidad(i)"
        @change="SET_UNIDADES(unidadesInscritas)"
      ></v-checkbox>
    </div>
    
    <v-btn color="rgb(94, 189, 153)" :disabled="validaInscripcion" @click="CAMBIO_PASO(3)"> Continuar</v-btn>

  </v-card>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import Swal from "sweetalert2";

export default {
  data() {
    return {
     courseRules:[
        v => !!v || 'El Curso es requerido'
      ],
      tipoInscripcionesRules:[
         v => !!v || 'La modalidad es requerida'
      ],
      isCupos: true,
      tipoinscripcion_id: null,
      fecha: null,
      cuotasInscritas: [],
      unidadesInscritas: [],
      cursoTotall: false,
    };
  },
  computed:{
    ...mapState('register',['course','cursoTotal']),
    ...mapGetters('register',['courseName','tipoInscripciones','fechas','tipo','montoTotalCuotas']),
    isMes(){
      if (this.tipo.tipo == 'cuotas') {
        return true
      }
      return false
    },
    isUnidades(){
      if (this.course) {
        if (this.course.area.isUnidades == 1) {
          return true
        }
      }
      return false
    },
    ifFechas(){
      if (this.course) {
        if (this.course.area.isFecha == 1) {
          return true
        }
      }
      return false
    },
    validaCuota(){
      var arr = [false]
      if (this.tipo){
        for (var i = 0; i < this.tipo.cantCuotas; i++) {
          if(i < this.cuotasInscritas.length){
            arr.push(false)
          }else{
            arr.push(true)
          }
        }
        var n = this.cuotasInscritas.length+1
        arr[n] = false
      }
      return arr
    },
    validaUnidad(){
      if (this.isMes) {
        var unidadesMarcar = this.tipo.cantUnidades
        var calculo = this.cuotasInscritas.length*unidadesMarcar

        if (!this.isUnidades) {
          calculo = this.course.unidades.length
        }
        var arr = []
        for (var i = 0; i < this.course.unidades.length; i++) {
          arr.push(true)
        }
        for (var il = 0; il < calculo; il++) {
          arr[il] = false
        }
        return arr
      }
      return []
    },

    isRequireCupos(){
      if (this.course) {
        if (this.course.area.isCupos == 1) {
          return true
        }
      }
      return false
    },
    validaInscripcion(){
      if (!this.tipoinscripcion_id) {
        console.log('no selecciono inscripcion')
        return true
      }
      if (this.ifFechas && !this.fecha) {
        console.log('no selecciono fecha')

        return true
      }
      if (this.isRequireCupos){
        if (this.isCupos) {
          if (this.cuotasInscritas.length == 0) {
        console.log('no selecciono cuota')

            return true
          }
        }
      }
      if (this.course) {
        if ((this.isUnidades || !this.isMes) && this.course.unidades.length > 0) {
          if (this.unidadesInscritas.length == 0) {
        console.log('no selecciono unidades')

            return true
          }
        }
      }
      return false
    }
  },
  filters: {
    monto(amount){
      amount += ''; // por si pasan un numero en vez de un string
      var amount_parts = amount.split('.'),
          regexp = /(\d+)(\d{3})/;
      while (regexp.test(amount_parts[0]))
          amount_parts[0] = amount_parts[0].replace(regexp, '$1' + '.' + '$2');
      return '$ '+amount_parts.join('.');
    }
  },
  watch:{
    fecha: 'quiereSala',
    validaUnidad: 'llenaUnidades'
  },
  mounted() {
  },
  created() {
  },
  methods: {
    ...mapMutations('register',['SET_CURSOTOTAL','SET_TIPOINSCRIPCION_ID','SET_FECHA_ID','SET_CUOTAS','SET_UNIDADES','CAMBIO_PASO']), 
    nameUnidad(element){
      if (this.isMes) {
        return element.name
      }else{
        return element.name +' - Arancel: '+ this.monto(element.monto)
      }
    },
    monto(amount){
      amount += ''; // por si pasan un numero en vez de un string
      var amount_parts = amount.split('.'),
          regexp = /(\d+)(\d{3})/;
      while (regexp.test(amount_parts[0]))
          amount_parts[0] = amount_parts[0].replace(regexp, '$1' + '.' + '$2');
      return '$ '+amount_parts.join('.');
    },
    async quiereSala(){
      if (this.isRequireCupos) {
        this.fechas.filter((element)=>{
          if (element.id == this.fecha) {
            if (element.cupos == 0) {
              this.isCupos = false
              Swal.fire({
                title:'Espera...',
                text:'No poseemos cupos disponible en este horario, puedes seleccionar otro o ingresar a nuestra sala de espera', 
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: `Ingresar a la Sala de Espera`,
                confirmButtonColor: 'rgb(94, 189, 153)',
                cancelButtonText: `Seleccionar otro horario`
              }).then((result) => {
                if (result.isConfirmed) {

                  this.addSala()

                } else if (result.isDismissed) {
                  // console.log('continuar')
                }
              })
            }else{
              this.isCupos = true

            }
          }
        })
      }
    },
    async addSala(){
      const response = await this.$store.dispatch("register/ADDSALA")
      if (response.data.status) {
        Swal.fire('Espera...', response.data.mensaje, 'success').then(()=>{
          this.$router.push('/user/home')
        })
      }else{
        Swal.fire('Espera...', response.data.mensaje, 'error').then(()=>{
          this.$router.push('/user/home')
        })
      }
    },
    llenaUnidades(){
      if (this.isMes) {
        if ( this.course.unidades.length > 0) {

          this.unidadesInscritas = []
          this.validaUnidad.forEach((element, index)=>{
            if (!element) {
              this.unidadesInscritas.push(this.course.unidades[index])
            }
          })
          this.SET_UNIDADES(this.unidadesInscritas)
        }else{
          if (this.course.area.isUnidades == 1 ) {
            Swal.fire('Lo sentimos!', 'No podemos continuar con la inscripción ya que no existen unidades disponibles', 'error').then(()=>{
            this.$router.push('/user/home')
          })
          }
          
        }
      }
    },
    totalCurso(){
      this.SET_CURSOTOTAL(this.cursoTotall)
      if (this.cursoTotall) {
        this.cuotasInscritas = []
        for (var i = 1; i <= this.tipo.cantCuotas; i++) {
          this.cuotasInscritas.push(i)
        }
      }else{
        console.log('salio')
        this.cuotasInscritas = []
      }
      this.SET_CUOTAS(this.cuotasInscritas)
    }

  },
};
</script>
<style>
</style>