<template>
   <v-btn color="rgb(94, 189, 153)" @click="continuar"> Continuar</v-btn>
</template>


<script>
import { mapState, mapGetters } from 'vuex'
import Swal from "sweetalert2";

export default {
  name: 'efectivoPago',
  props:{
    monto:{
      type: Number,
      required: true
    },
    pago_case:{
      type: String
    },
    
  },
  components: {

  },
  data(){
    return{
    }
  },

  computed:{
    ...mapState('register',['course','cursoTotal','users_id','inscripcionData']),
    ...mapState('auth',['authUser','user']),
    ...mapState('home',['config']),
    ...mapGetters('register',['totalApagar']),
    ...mapState('users',['curso_select']),
    curso(){
      if (this.course) {
        return this.course
      }else{
        return this.curso_select
      }
    },
    inscripcion_id(){
      if(this.inscripcionData.id){
        return this.inscripcionData.id
      }else{
        return this.curso_select.inscripcion
      }
    }
  },
  create(){
    
  },
  mounted(){
     
  },
  methods:{
    continuar(){
      if(this.pago_case != 'Unidades'){
        this.$router.push(`/success/${this.curso.id}/${this.user.id}/${this.inscripcion_id}?metodopago=Efectivo&pago_case=${this.pago_case}`)
      }else{
        this.inscripcionUnidades()
      }
    },
    async inscripcionUnidades(){
      try{
        const response = await this.$store.dispatch("users/INSCRIPCIONUNIDADES",'Efectivo');
        if (response.data.status) {
          Swal.fire('Registro Procesado',response.data.mensaje+' ...Te redirigiremos a tus cursos', 'success').then(()=>{
            this.$router.push('/user/home')
          })
        }else{
          Swal.fire('Espera...', response.data.mensaje, 'error')
        }
      }catch(e){
        console.log(e.response.data)
      }
    },
  } 
}
</script>


