<template>
  <v-card elevation="0" class="mb-12" v-if="course">
    <table style="width: 100%">
      <tr>
        <td>Nombre del curso:</td>
        <td><b>{{ course.name }}</b></td>
      </tr>
      <tr v-if="inscripcion">
        <td>Inscripción</td>
        <td>
          <p class="mb-0" v-if="bonificado">Inscripción Bonificada: <br> Tiempo de promoción <CountDown :seconds="segundosFinales" /></p>

          <p class="mb-0"><b :class="bonificado ? 'tachado':''">{{ course.precio_inscripcion | monto }} </b> </p> 
        </td>
      </tr>
      <tr v-if="cursoTotal ">
        <td v-show="totalidadDelCurso > 0">Curso $: </td>
        <td v-show="totalidadDelCurso > 0"><b>{{ totalidadDelCurso | monto }}</b></td>
      </tr>

      <tr v-else v-for="(i , key) in cuotasInscritas" :key="key">
        <td>Cuota #{{ i }}</td>
        <td>{{ tipo.montCuotas | monto }}</td>
      </tr>

      <span  v-if="tipo.tipo == 'unidad' && unidadesInscritas.length > 0">

        <tr v-for="(i, key) in unidadesInscritas" :key="key">
          <td>{{ i.name }}</td>
          <td><b>{{ i.monto | monto }}</b></td>
        </tr>

      </span>
      <tr v-if="cupon">
        <td>Descuento por cupón</td>
        <td><b>-{{ cupon.restar | monto  }}</b></td>
      </tr>
      <tr>
        <td>Total:</td>
        <td><b>{{ totalApagar | monto }}</b></td>
      </tr>
      <tr>
        <td align="right" colspan="2">
          <CuponDescuento></CuponDescuento>
        </td>
      </tr>
    </table>
    <!-- <div v-else> -->
  <!--   <p v-if="cuotasInscritas.length > 0">Cantidad de cuotas a pagar: <b>{{ cuotasInscritas.length }} Cuota<span v-if="cuotasInscritas.length > 1"><b>s</b></span> de {{ tipo.montCuotas | monto }} </b></p>
    </div> --> 
    
    <v-btn color="rgb(94, 189, 153)"  @click="inscripcionCurso"> Continuar</v-btn>
  </v-card>
</template>
<script>
import { mapState, mapMutations,mapGetters } from 'vuex'
import CountDown from '../../../components/CountDown.vue'
import CuponDescuento from '@/components/CuponDescuento'
import Swal from "sweetalert2";

export default {
  data() {
    return {
      
      
    };
  },
  components: {
    CountDown, CuponDescuento
  },
  computed:{
    ...mapState('register',['course','cupon','cuotasInscritas','unidadesInscritas','inscripcion','cursoTotal','cursoTotal']),
   ...mapGetters('register',['tipo','bonificado','segundosFinales','totalApagar','totalidadDelCurso','totalApagarDolares'])
  },
  watch:{
    // user: 'cambiaUser'
  },
  filters: {
    monto(amount){
      amount += ''; // por si pasan un numero en vez de un string
      var amount_parts = amount.split('.'),
          regexp = /(\d+)(\d{3})/;
      while (regexp.test(amount_parts[0]))
          amount_parts[0] = amount_parts[0].replace(regexp, '$1' + '.' + '$2');
      return '$ '+amount_parts.join('.');
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
    ...mapMutations('register',['CAMBIO_PASO','SET_INSCRIPCION']),
    ...mapMutations('home',['changeLoading']),
    async inscripcionCurso(){
      try{
        this.changeLoading(true)
        const response = await this.$store.dispatch("register/INSCRIPCION");
        if (response.data.status) {
          this.changeLoading(false)
          this.SET_INSCRIPCION(response.data.inscripcion.id) //ID DE LA INSCRIPCION 
          this.CAMBIO_PASO(4)
        }else{
          this.changeLoading(false)
          Swal.fire('Espera...', response.data.mensaje, 'error')
        }
      }catch(e){
        console.log(e.response.data)
      }
    },
  },
};
</script>
<style >
  .tachado{
    text-decoration:line-through;
  }
  td {
      font-size: 14px;
      padding: 6px 4px;
  }
  table span{
    width: 100%;     
    display: contents;
  }
</style>