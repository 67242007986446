<template>
  <section class="container">
    <h1 class="font-weight-bold mb-4">Hola {{ user.name }}</h1>

    <div class="mb-4" v-if="salaEspera.length > 0">
      <h3>En sala de espera</h3>
      <v-row>
        <v-col cols="6" sm="4" md="3" v-for="(e, key) in salaEspera" :key="key">
          <v-card
            elevation="2"
            color="rgb(94, 189, 153)"
            class="text-center" 
          >
            <v-card-text class="text-center" >
              <v-card-title style="color: white; font-weight: bold" v-text="e.curso.name">
              </v-card-title>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>  
    <div v-if="concursos">
      <h3>Tus Cursos</h3>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          v-for="(item, key) in users_courses"
          :key="key"
          class="d-flex align-stretch"
        >
          <boxCurso
            :item="item"
            :user="true"
          ></boxCurso>
            
        </v-col>
      </v-row>
    </div>
    <div v-if="sincursos && salaEspera.length > 0">
      <h1>No posee cursos inscritos</h1>

      <router-link to="/courses-list" class="link-none">
        <v-btn color="rgb(94, 189, 153)" style="color: white; font-weight: bold" class="d-block col-4 my-5 mx-auto">
          Registrar cursos
        </v-btn>
      </router-link>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import boxCurso from '../../components/boxCurso.vue'
export default {
  name: '',
  props:{
    item:{
      type: Object
    }
  },
  watch:{
    sincursos: 'redireccion'
  },
  components: {
    boxCurso  
  },
  data(){
    return{
      concursos: false,
      sincursos: false,
    }
  },
  computed:{
    ...mapState(['URL_IMG']),
    ...mapState('auth',['user']),
    ...mapState('users',['users_courses','salaEspera']),
    ...mapGetters('auth',['USER'])
  },
  mounted(){

  },
  created(){
    window.scrollTo(0,0)
    // console.log(this.$router.currentRoute.name)
    this.users_home()
  },
  methods:{
    ...mapMutations('auth',['CLEAR_DATA','CLEAR_TOKEN']),
    redireccion(){
      if (this.sincursos) {
        if (this.salaEspera.length == 0) {
          this.$router.push('/courses-list')
        }
      }
      
    },
    async users_home(){
      try{
        await this.$store.dispatch('users/USER_HOME',this.USER.id)
        if (this.users_courses.length > 0) {
          this.concursos = true
        }else{
          this.sincursos = true
        }
        // 
      }catch(e){
        if (e.response.status == 401) {
          this.CLEAR_DATA()
          this.CLEAR_TOKEN()
          this.$router.push(`/login`)
        }
      }
    }
  }
}
</script>

<style lang="scss">

</style>
