<template>
  <div>
    <div v-if="isLoadingMP" class="ctn d-flex align-center">
      <loading 
        :active.sync="isLoadingMP" 
        :is-full-page="false"
        :width="18"
        :color="'#5ebd99'"
      ></loading>
    </div>
    <div class="cho-container"></div>
  </div>
</template>

<script src="https://sdk.mercadopago.com/js/v2"></script>
<script>
import { mapState, mapGetters } from 'vuex'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: 'mercadoPago',
  props:{
    monto:{
      type: Number,
    },
    pago_case:{
      type: String
    }
  },
  components: {
    Loading
  },
  data(){
    return{
      YOUR_PREFERENCE_ID: null,
      isLoadingMP: null,
      metodo: ''
    }
  },

  computed:{
    ...mapState('register',['course','cursoTotal','users_id','inscripcionData']),
    ...mapState('auth',['authUser','user']),
    ...mapState('home',['config']),
    ...mapGetters('register',['totalApagar']),
    ...mapState('users',['curso_select','unidades_inscritas']),
    montoTotal(){
      if(this.totalApagar){
        return this.totalApagar
      }else{
        return this.monto
      }
    },
    curso(){
      if (this.course) {
        return this.course
      }else{
        return this.curso_select
      }
    },
    inscripcion_id(){
      if (this.inscripcionData.id) {
        return this.inscripcionData.id
      }else{
        return this.curso_select.inscripcion
      }
    }
  },
  create(){
    this.isLoadingMP = true 

  },
  mounted(){
    this.isLoadingMP = true 
    this.getPreference()
 
  },
  methods:{
    selectMP(){
      this.metodo = 'MP'
      this.isLoadingMP = true 
      this.getPreference()
    },
    async getPreference(){
      try{
        let obj = { curso_id: this.curso.id,
                    users_id: this.user.id,
                    name: this.curso.name, 
                    precio: this.montoTotal,
                    inscripcion_id: this.inscripcion_id,
                    pago_case: this.pago_case
                  }
        if (this.pago_case == 'Unidades') {
          obj.unidades = this.unidades_inscritas
        }
        const response = await this.$store.dispatch("register/GET_CREATE_PREFERENCE_MP", obj);
        this.YOUR_PREFERENCE_ID = response.data.id
        this.generate()
      }catch (e){
        console.log(e)
      }
    },
    generate(){
      document.querySelector('.cho-container').innerHTML = ''
      // Agrega credenciales de SDK
      this.isLoadingMP = false
      const mp = new MercadoPago(process.env.VUE_APP_MP_PUBLIC_KEY, {
            locale: 'es-AR'
      });

      // Inicializa el checkout
      mp.checkout({
          preference: {
              id: this.YOUR_PREFERENCE_ID
          },
          render: {
                container: '.cho-container', // Indica dónde se mostrará el botón de pago
                label: 'Pagar', // Cambia el texto del botón de pago (opcional)
          }
      });
    }
  }
}
</script>


