<template>
  <v-col  cols="12" sm="10" class="mx-auto" v-if="curso_select">

    <h1 class="mt-3 mb-4"><b>{{ curso_select.name }}</b></h1>
    <v-row >
        <v-col cols="12" sm="6" md="4" class="px-5">

          <div class="cont-unidades">
            <div v-if="isunidades">
              <v-select
                :items="curso_select.unidades"
                label="Unidades Inscriptas"
                item-text="name"
                item-value="id"
                v-model="unidadActive"
                @change="cambiaUnidad(), itemActive = ''"
                outlined
              ></v-select>
            </div>
            <!--  -->
            <v-progress-linear
              v-if="unidad.id != null"
              :value="unidad.progreso"
              rounded
              striped
              height="25"
              color="rgb(94, 189, 153)"
              class="mb-3"
            >
              <strong>{{ unidad.progreso }}%</strong>
            </v-progress-linear>
            <!--  -->
            <div>
              <v-card elevation="3">
                <v-navigation-drawer floating permanent width="100%">
                  <v-list dense rounded>
                    <v-list-item v-for="(item, key) in items_tabs" :key="key" link
                       @click="itemActive = item.name"
                       :class="itemActive == item.name ? 'active':''"
                    >
                      <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content >
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-navigation-drawer>
              </v-card>
            </div>
            <!--  -->
            <v-card elevation="" class="mt-7"  v-if="otrasUnidades.length > 0 && curso_select.area.isUnidades == 1">
                <v-toolbar dense flat color="grey lighten-4">
                  <p class="title my-auto">Otras unidades de este curso</p>
                </v-toolbar>
                 <v-list subheader>

                  <v-list-item
                    v-for="(item, i) in otrasUnidades"
                    :key="i"
                    class="item-unidad"
                  >
                    <v-list-item-content >
                      <v-list-item-title ><b>{{ item.name }}</b></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-col cols="9" class="mx-auto">
                 <router-link class="link-dark w-100" :to="'/register-unidades?course='+$route.params.slug">
                  <v-btn rounded block color="#4CAF50" dark class=""><span style="font-weight: bold">Inscribir</span></v-btn>
                </router-link>
                </v-col>
              </v-card>
          </div>
        </v-col>

        <v-col v-if="unidad.active" cols="12" sm="6" md="8" class="">
          <h1  v-if="unidad.id != null" class="text-center mb-4"><b>{{ unidad.name }}</b></h1>
          <v-divider v-if="unidad.id != null"></v-divider>

          <v-card flat  elevation="3" v-if="itemActive == 'Inicio' || itemActive == '' ">
            <v-container>
              <v-row>
                <v-col cols="12" v-html="description"></v-col>
                <v-divider class="mt-5"></v-divider>
              </v-row>
            </v-container>
          </v-card>

          <v-card flat  elevation="0" v-if="itemActive == 'Foro'">
            <Foro></Foro>
          </v-card>

          <!-- DOCUMENTOS -->
          <v-card flat  elevation="3" v-if="itemActive == 'Documentos' && curso_select.area.isDocumentos == 1">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <h3 v-if="unidad.id == null"> Debe seleccionar una unidad para ver sus documentos</h3>
                  <h3 v-if="unidad.id != null && unidad.documentos.length == 0"> No posee documentos</h3>
                  <v-list v-else >
                    <v-list-item
                      v-for="(item, i) in unidad.documentos"
                      :key="i"
                      class="item-unidad"
                      title="Descargar"
                      @click="descargarDocumento(item.enlace)"
                    >
                      <v-list-item-content >
                        <v-list-item-title ><b>{{ item.description }}</b></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon >
                          mdi-download
                        </v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <!-- EVALUACIONES -->
          <v-card flat  elevation="3" v-if="itemActive == 'Evaluaciones' && curso_select.area.isEvaluaciones == 1">
            <v-container>
              <v-row>
                <v-col cols="12">

                  <h3 v-if="unidad.id == null"> Debe seleccionar una unidad para ver sus evaluaciones</h3>
                  <h3 v-if="unidad.id != null && unidad.actividades.length == 0"> No posee actividades</h3>

                  <v-list v-else >
                    <div v-if="actividades_unidades.length == 0">No posee evaluaciones</div>
                    <v-list-item
                      v-for="(item, i) in actividades_unidades"
                      :key="i"
                      class="item-unidad"
                      :class="[ validaIntento(item).status == 'APROBADO'  ? 'item-unidad-resuelto':'',
                                validaIntento(item).status == 'REPROBADO' &&  validaIntento(item).contador == true ? 'item-unidad-reprobado':'',
                                validaIntento(item).status == 'POR EVALUAR'  ? 'item-unidad-pendiente':''
                                 ]"
                      @click="ejecutarEvaluacion(item)"
                    >
                    <v-list-item-content >
                      <v-list-item-title class="d-flex justify-space-between" >
                        {{ item.name }}
                        <CountDown v-if="validaIntento(item).status == 'REPROBADO' &&  validaIntento(item).contador == true" :mensaje="'Recarga la página para intentarlo nuevamente'" :seconds="item.ultimo_intento.proximo_intento"></CountDown>
                        <p class="mb-0" 
                        v-if="validaIntento(item).status != 'SIN INTENTOS'"
                        :class="[validaIntento(item).status == 'APROBADO'  ? 'white--text':'',
                                validaIntento(item).status == 'REPROBADO' &&  validaIntento(item).contador == true ? 'white--text':'']">
                          {{ item.ultimo_intento.status }}</p>
                       </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon v-if="validaIntento(item).status == 'SIN INTENTOS' || validaIntento(item).status == 'REPROBADO' &&  validaIntento(item).contador == false">
                        mdi-eye
                      </v-icon>
                      <v-icon v-if="validaIntento(item).status == 'APROBADO'" >
                        mdi-check
                      </v-icon>
                    </v-list-item-icon>
                    </v-list-item>
                  </v-list>

                </v-col>
              </v-row>
            </v-container>
            <modalEvaluaciones ref="et" id="et" v-if="unidad.id != null"></modalEvaluaciones>
          </v-card>

          <!-- CLASES -->
          <v-card flat   elevation="3" v-if="itemActive == 'Clases' && curso_select.area.isClases == 1">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <h3 v-if="unidad.id == null"> Debe seleccionar una unidad para ver sus clases</h3>
                  <h3 v-if="unidad.id != null && unidad.clases.length == 0"> No posee clases</h3>
                  <v-list v-else >
                    <v-list-item
                      v-for="(item, i) in unidad.clases"
                      :key="i"
                      class="item-unidad"
                      @click="openClase(item)"
                    >
                      <v-list-item-content >
                        <v-list-item-title class="d-flex justify-space-between" >
                          <b>{{ item.name }}</b>
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon >
                          mdi-eye
                        </v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                  
                </v-col>
              </v-row>
            </v-container>
            <modalClase v-if="unidad.id != null"></modalClase> 
          </v-card>

          <!-- CERTIFICADO -->
          <v-card flat   elevation="3" v-if="itemActive == 'Certificado'">
            <div v-if="!rutaCertificado" style="padding: 2rem;" class=" d-flex justify-center align-center">

              <v-btn  color="#5ebd99" @click="generarCertificado" :loading="loadingC">
                Generar Certificado
              </v-btn>
            </div>
            <iframe v-if="rutaCertificado" :src="rutaCertificado" width="100%" height="500px" frameborder="0"></iframe>
          </v-card>
        </v-col>
        <v-col v-else>
          <h2 v-if="isunidades">La unidad anterior es requerida</h2>
        </v-col>
    </v-row>

  </v-col>
</template>
<script>
import moment from "moment";
import { mapGetters, mapState, mapMutations } from 'vuex'
import modalEvaluaciones from '../../components/modalEvaluaciones.vue'
import modalClase from '../../components/modalClase.vue'
import Foro from '../../components/foroComponent.vue'
import CountDown from '../../components/CountDown.vue'
import Swal from "sweetalert2";

export default {
  components: {
    modalEvaluaciones,
    modalClase,
    Foro,
    CountDown
  },
  data: () => ({
    itemActive: 'Inicio',
    tab: null,
    unidadActive: null,
    dialog: false,
    loadingC: false
  }),
  filters: {
    date(value) {
      return moment(value).locale("es").format("MM/DD");
    },
  },
  watch:{
    isunidades: 'firstCarga'
  },
  created() {
    this.firstCarga()
    this.course({
      slug: this.$route.params.slug,
      area: this.$route.params.area_id,
      user_id: this.USER.id
    });
    
  },
  mounted(){
    setTimeout(function(){
      document.querySelector('#clgo').style = 'display: none'
    },2000)

  },
  computed: {
    ...mapGetters('auth',['USER']),
    ...mapState(['URL_IMG']),
    ...mapState('users',['curso_select','actividades','evaluacion','actividadesResueltas','otrasUnidades','unidad','rutaCertificado']),
    ...mapGetters('users',['items_tabs','validaEvaluacion']),
    isunidades(){
      if (this.curso_select.unidades != undefined) {
        if (this.curso_select.unidades.length > 0) {
          return true
        }
      }
      return false
    },
    description(){
      if (this.unidad.id != null) {
        return this.unidad.description
      }
      return this.curso_select.descripcion
    },
    video(){
      if (this.unidad.id != null) {
        return  this.URL_IMG+this.unidad.video
      }
      return  this.URL_IMG+this.curso_select.video
    },
    actividades_unidades(){
      if (this.actividades) {
        return this.actividades.filter((element) => element.cursos_unidades_id == this.unidad.id)
      }
      return  []
    }
  },
  methods: {
    ...mapMutations('users',['CHANGE_DIALOG_EVALUACION','SET_EVALUACION','CHANGE_DIALOG_CLASE','SET_CLASE','SET_UNIDAD','SET_RUTA_CERTIFICADO']),
    ...mapMutations('auth',['CLEAR_DATA','CLEAR_TOKEN']),
    async course(obj){
      try{
        await this.$store.dispatch('users/COURSE_SELECT',obj)
      }catch(e){
        if (e.response.status == 401) {
          this.CLEAR_DATA()
          this.CLEAR_TOKEN()
          this.$router.push(`/login`)
        }
      }
    },
    async generarCertificado(){
      this.loadingC = true
      try{
        let obj = {
          users_id: this.USER,
        }
        let response = await this.$store.dispatch('users/GENERATE_CERTIFICADO',obj)
        this.mostrarCertifcado(response)
      }catch(e){
        console.log(e)
      }
    },
    mostrarCertifcado(response){
      if(response.data.status){
          Swal.fire(
            'Genial!',
            response.data.mensaje,
            'success'
          ).then(()=>{
            this.loadingC = false
            this.SET_RUTA_CERTIFICADO(response.data.ruta)
          })
      }else{
        Swal.fire(
          'Espera!',
          response.data.mensaje,
          'error'
        ).then(()=>{
            this.loadingC = false
          
           this.SET_RUTA_CERTIFICADO(null)
        })
      }
    },
    cambiaUnidad(){
     this.SET_UNIDAD(this.curso_select.unidades.find((element)=> element.id == this.unidadActive))
     this.validaEvaluacion
    },
    firstCarga(){
      if (this.isunidades) {

        this.unidadActive = this.curso_select.unidades[0].id
        this.cambiaUnidad()
      }
    },
    documentsColor(ext) {
      switch (ext) {
        case "docx":
          return "blue";
        case "pdf":
          return "red";
        case "xlsx":
          return "green";
        case "xls":
          return "green";
        case "csv":
          return "green";
        default:
          return "blue";
      }
    },
    descargarDocumento(enlace){
      var url = this.URL_IMG+enlace
      window.open(url)
    },
    ejecutarEvaluacion(actividad){
      this.SET_EVALUACION(actividad)
      this.CHANGE_DIALOG_EVALUACION(true)
    },
    validaIntento(actividad){

        if (actividad.ultimo_intento.status == 'SIN INTENTOS') {
          return { status: 'SIN INTENTOS' }
        }

        if(actividad.ultimo_intento.status == 'POR EVALUAR'){
          return { status: 'POR EVALUAR', contador: false }
        }

        if (actividad.ultimo_intento.status == 'APROBADO') {
          return { status: 'APROBADO' }
        }
        if (actividad.ultimo_intento.status == 'REPROBADO') {
          if (actividad.ultimo_intento.proximo_intento > 0) {
            return { status: 'REPROBADO', contador: true }
          }else{
            return { status: 'REPROBADO', contador: false }
          }
        }

    },
    openClase(item){
      this.CHANGE_DIALOG_CLASE(true)
      this.SET_CLASE(item)
    }

  },
};
</script>
<style lang="scss">
  .v-list-item.active{
    background: #5ebd99;
    
    .v-list-item__title{
      color: white;
    }
    i{
      color: white;
    }
  }
  video{
    width: 100%;
  }
  .item-unidad{
    cursor: pointer;
    &:hover{
      background-color: rgba(0,0,0,.05);
    }
    &.active{
      background: #00897B;
      .v-list-item__title{
        color: white;
      }
      
    }
    &.item-unidad-resuelto{
      background: #5ebd99;
      .v-list-item__title{
        color: white;
      }
      &:hover{
        background: #41a780;
      }
    }
    &.item-unidad-reprobado{
      background: #E53935;
      .v-list-item__title{
        color: white;
      }
      &:hover{
        background: #b72420;
      }
    }
    &.item-unidad-pendiente{
      background: #FFD54F;
      .v-list-item__title{
        color: black;
      }
    }
  }
  .cont-unidades{
    position: sticky;
    top: 10px;
  }
</style>
